$width_screen: 1920px;
#footerid {
  background-image: url("../../../public/css/img/footer/footer.png");
  background-position:center;
  background-size: cover ;
  background-repeat: no-repeat;
}
.partner-imag{
  width: 10%;
  margin-right: 50px;
}
.footerBackground{
  width:100%;
  //background-image: url({{asset('css/img/footer/footer.png')}});
  background-image: url("../../../public/css/img/footer/footer.png");
  background-position:center ;
  background-size: cover ;
  background-repeat: no-repeat;
  margin-top: -97px;

}
.centerElem{
  justify-content: center;
  margin: auto;
  width: 100%;
  font-size: 50px;
  text-align: center;
}
.footerBackground{
  position: relative;
  color: white;
  width: 100%;
  font-size: 1.04vw;
}

.image1{
  background-image: url("../../../public/css/icon/facebookb.png");
}
.image2{
  background-image: url("../../../public/css/icon/linkedinb.png");
}
.image3{
  background-image: url("../../../public/css/icon/twitterb.png");
}

.footerBackground a,.footerBackground ul li,.footerBackground ul{
  color: white;
  text-decoration: none!important;
  list-style: none ;
  padding : 0 ;
}
.div-container-data-footer{
  padding-top: 3.5%;
}

.footerBackground a:hover{
  color: red;
  text-decoration: none!important;
}

.footerBackground .title-footer{
  margin-bottom: 5%;
  font-size: 1.25vw ;
  font-weight: bold;
}
.footerBackground .title-footer,footer ul{
  text-align : left ;
}

ul li,ul,li {
  text-decoration: none!important;
  list-style: none;
}
.container-data-footer{
  display : flex ;
  justify-content: center;
}
.footer-colonne:not(:last-of-type){
  margin-right: 21%;
}
.container-icones-footer{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.icon-footer{
  width:3%;
  margin-bottom: 2.34375%;
}
.icon-footer:not(:last-of-type)
{

  margin-right:3.44%;
}

@media screen and (max-width: 1024px) and (min-height: 620px) {

  .footerBackground{
    font-size: 2.5vw;

    .title-footer{
      font-size: 3vw;
    }
    .div-container-data-footer {
      padding-left: 8.5%;
      padding-right: 8.5%;
    }
    .icon-footer {
      width: 10%;
    }
  }

}

@media screen and (max-width: 480px){
  .footerBackground{
    font-size: 2.5vw;

    .title-footer{
      font-size: 3vw;
    }
    .div-container-data-footer {
      padding-left: 8.5%;
      padding-right: 8.5%;
    }

    .icon-footer {
      width: 8%;
      display:inline-block;

      img{
        width: 100%;

      }
    }
  }

}